import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Snackbars = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [viewAll, setViewAll] = React.useState(false);
  const snackbarRef = React.useRef();

  React.useImperativeHandle(ref, () => ({
    openSnackbar() {
      setOpen(true);
    },
  }));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setViewAll(false);
    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }} ref={snackbarRef}>
      <Snackbar
        open={open}
        // autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={props.snackbarErrStatus ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {typeof props.message === "string" ? (
            props.message
          ) : (
            <div className="w-fit max-h-[10rem] overflow-auto">
              {props.message[0]}
              <br />
              <br />
              <p
                className="cursor-pointer italic"
                onClick={() => {
                  if (viewAll === false) {
                    setViewAll(true);
                  }
                }}
              >
                Click here to view all the errors
              </p>
              {viewAll &&
                props.message.slice(1).map((err) => {
                  return (
                    <p>
                      {err} <br />
                    </p>
                  );
                })}
            </div>
          )}
        </Alert>
      </Snackbar>
    </Stack>
  );
});

export default Snackbars;
