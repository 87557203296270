import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import BasicButton from "../Button";
import { GetMarkTemplate } from "../../../apis/fetcher/TeacherMarksEntry/subjectMarksEntry";
import { saveAs } from "file-saver";
import UploadButton from "../../UploadButton";
import { useMutation } from "@tanstack/react-query";
import instance from "../../../instance";
import Cookies from "js-cookie";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MarkTemplate = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState("idel");
  const [file, setFile] = React.useState(null);
  React.useImperativeHandle(ref, () => ({
    OpenMarkTemplate() {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
    setFile(null);
  };
  const downloadMarkTemplte = async () => {
    setStatus("downloading");
    const res = await GetMarkTemplate(
      props.id,
      props.sectionId,
      props.subjectId
    ).catch((err) => {
      setStatus("idel");
      props.showSuccessMsg(err.response.data.message, true);
    });
    const blob = new Blob([res], {
      type: "application/json",
    });
    saveAs(
      blob,
      `marksEntry_template_${props.id}_${
        props.subjectId
      }_${new Date().getDate()}_${
        new Date().getMonth() + 1
      }_${new Date().getFullYear()}.xlsx`
    );
    setStatus("idel");
  };

  const mutation = useMutation({
    mutationFn: async () => {
      setStatus("uploading");
      const formData = new FormData();
      formData.append("excelFile", file);
      const res = await instance({
        url: `liveApp/api/v1/marksEntryTemplate/upload/${props.id}/${props.sectionId}/${props.subjectId}`,
        method: "POST",
        data: formData,
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }).catch((err) => {
        setStatus("idel");
        props.showSuccessMsg(err.response.data.message, true);
      });
      if (res.status === 200 && res.data.success === true) {
        props.showSuccessMsg(res.data.message, false);
      }
      setStatus("idel");
    },
  });

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ fontStyle: "italic" }}>
          {"Upload Marks Entry Template"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="w-full flex justify-between items-center">
              <div className="flex flex-col gap-2">
                <h1 className="font-semibold italic">
                  Upload Your File(.xlsx)
                </h1>
                <UploadButton
                  name={"Browse"}
                  accept={".xlsx"}
                  disable={status === "uploading"}
                  uploadContent={(file) => {
                    setFile(file);
                  }}
                />
                <h1 className="font-semibold italic text-sm">{file?.name}</h1>
              </div>
              <div className="h-fit">
                <BasicButton
                  size={"small"}
                  disable={status === "downloading"}
                  text={`${
                    status === "downloading"
                      ? "Downloading..."
                      : "Download Template"
                  }`}
                  handleButtonAction={downloadMarkTemplte}
                />
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <BasicButton
            size={"small"}
            text={"Cancel"}
            handleButtonAction={handleClose}
          />

          <BasicButton
            size={"small"}
            text={`${status === "uploading" ? "Uploading..." : "Upload"}`}
            disable={!file || status === "uploading"}
            handleButtonAction={mutation.mutate}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default MarkTemplate;
